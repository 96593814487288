<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-justify-start">
                        <h4 class="tw-mb-0">{{ $t('system.general') }}</h4>
                    </div>
                </div>
                <div
                    class="tw-mt-2  tw-p-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-72 tw-overflow-auto"
                >
                    <div
                        class="tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-1 tw-outline-none tw-mb-1"
                    >
                        <strong> - Number of day allow reschedule</strong>
                        <div>
                            <input
                                v-model.number="model.num_day_allow_reschedule"
                                type="number"
                                class="tw-border tw-rounded-md tw-text-right tw-py-1 tw-w-20 tw-outline-none"
                                min="0"
                                :class="{
                                    'is-invalid': errors.has(
                                        'num_day_allow_reschedule'
                                    )
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('num_day_allow_reschedule')"
                            >
                                {{ errors.first('num_day_allow_reschedule') }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-1 tw-outline-none tw-mb-1"
                    >
                        <strong> - Reschedule penalty rate</strong>
                        <div>
                            <InputNumber
                                :max="100"
                                v-model="model.reschedule_penalty_rate"
                                :formatter="value => `${value}%`"
                                :parser="value => value.replace('%', '')"
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'reschedule_penalty_rate'
                                    )
                                }"
                                :min="0"
                            ></InputNumber>
                        </div>
                    </div>
                    <div
                        class="tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-1 tw-outline-none tw-mb-1"
                    >
                        <strong> - Pay off penalty rate</strong>
                        <div>
                            <InputNumber
                                :max="100"
                                v-model="model.pay_off_penalty_rate"
                                :formatter="value => `${value}%`"
                                :parser="value => value.replace('%', '')"
                                :class="{
                                    'ivu-form-item-error': errors.has(
                                        'pay_off_penalty_rate'
                                    )
                                }"
                                :min="0"
                            ></InputNumber>
                        </div>
                    </div>
                    <div
                        class="tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-1 tw-outline-none tw-mb-1"
                    >
                        <strong> - Decimal digit</strong>
                        <div>
                            <input
                                v-model.number="model.decimal_digit"
                                type="number"
                                class="tw-border tw-rounded-md tw-text-right tw-py-1 tw-w-20 tw-outline-none"
                                min="0"
                                :class="{
                                    'is-invalid': errors.has(
                                        'reschedule_penalty_rate'
                                    )
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('reschedule_penalty_rate')"
                            >
                                {{ errors.first('reschedule_penalty_rate') }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-1 tw-outline-none tw-mb-1"
                    >
                        <strong> - Idle Time Out (Minute)</strong>
                        <div>
                            <input
                                v-model.number="model.idle_time_out"
                                type="number"
                                class="tw-border tw-rounded-md tw-text-right tw-py-1 tw-w-20 tw-outline-none"
                                min="0"
                                :class="{
                                    'is-invalid': errors.has('idle_time_out')
                                }"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('idle_time_out')"
                            >
                                {{ errors.first('idle_time_out') }}
                            </div>
                        </div>
                    </div>
                    <div
                        class="tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-2 tw-outline-none tw-mb-1"
                    >
                        <strong> - Auto posting repayment</strong>
                        <div>
                            <i-switch
                                v-model="model.is_approve_transaction_required"
                            >
                            </i-switch>
                        </div>
                    </div>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'

export default {
    name: 'generalSetting',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            model: {}
        }
    },
    created () {
        this.model = this.value
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
<style scoped>
ol,
ul {
    padding-left: 2px;
}
</style>
